<template>
  <ul>
    <!-- <li>
      <router-link to="/" :class="currentPage == 'home' ? 'active' : ''">
        <span class="icon"><img src="@/assets/images/homepage.svg" /></span>
        <span class="title">{{ $t("homePage") }}</span>
      </router-link>
    </li> -->
    <!-- <li
      v-if="
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeNews.newsView
        )
      "
    >
      <router-link to="/news" :class="currentPage == 'news' ? 'active' : ''">
        <span class="icon"><img src="@/assets/images/news.svg" /></span>
        <span class="title">{{ $t("news") }}</span>
      </router-link>
    </li> -->
    <!-- <li
      v-if="
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeOffers.offersView
        )
      "
    >
      <router-link
        to="/offers"
        :class="currentPage == 'offers' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/offers.svg" /></span>
        <span class="title">{{ $t("offers") }}</span>
      </router-link>
    </li> -->
    <!-- <li
      v-if="
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeServicesReservations
            .servicesReservationView
        )
      "
    >
      <router-link
        to="/services-reservations"
        :class="currentPage == 'services-reservations' ? 'active' : ''"
      >
        <span class="icon"
          ><img src="@/assets/images/services-reservations.svg"
        /></span>
        <span class="title">{{ $t("servicesReservations") }}</span>
      </router-link>
    </li> -->
    <!-- <li
      v-if="
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeServicesReservations
            .servicesReservationViewPay
        )
      "
    >
      <router-link
        to="/services-payments"
        :class="currentPage == 'services-payments' ? 'active' : ''"
      >
        <span class="icon"
          ><img src="@/assets/images/services-payments.svg"
        /></span>
        <span class="title">{{ $t("servicesPayments") }}</span>
      </router-link>
    </li> -->
    <li
      class="sub-menu"
      v-if="
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeEmployees.employeesView
        ) ||
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeEmployeeBranches
            .employeeBranchesView
        ) ||
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeEmployeeSalarySetting
            .employeeSalarySettingAdd
        ) ||
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeEmployeeWorkSchedule
            .employeeWorkScheduleAdd
        ) ||
        checkPrivilege(
          usersEstablishmentPrivilegeData
            .usersPrivilegeEmployeeOfficialHolidaysPlans
            .employeeOfficialHolidaysPlansAdd
        ) ||
        checkPrivilege(
          usersEstablishmentPrivilegeData
            .usersPrivilegeEmployeesInputSheetAttendance
            .employeesInputSheetAttendanceView
        ) ||
        checkPrivilege(
          usersEstablishmentPrivilegeData
            .usersPrivilegeEmployeesInputSheetAttendanceMerge
            .EmployeesInputSheetAttendanceMergeView
        )
      "
    >
      <a
        v-b-toggle.theEmployees
        :class="currentPage == 'theEmployees' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/employees.svg" /></span>
        <span class="title">
          {{ $t("theEmployees") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
      <b-collapse id="theEmployees">
        <ul>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData.usersPrivilegeEmployees
                  .employeesView
              )
            "
          >
            <router-link
              to="/employees"
              :class="currentSubPage == 'employees' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/employees.svg"
              /></span>
              <span class="title">{{ $t("theEmployees") }}</span>
            </router-link>
          </li>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData.usersPrivilegeEmployeeBranches
                  .employeeBranchesView
              )
            "
          >
            <router-link
              to="/employee-branches"
              :class="currentSubPage == 'employee-branches' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/employee-branches.svg"
              /></span>
              <span class="title">{{ $t("employee-branches") }}</span>
            </router-link>
          </li>
          <li>
            <router-link
              to="/employee-grants"
              :class="currentSubPage == 'employee-grants' ? 'active' : ''"
            >
              <span class="icon"><img src="@/assets/images/grants.svg" /></span>
              <span class="title">{{ $t("employeeGrants.modelName") }}</span>
            </router-link>
          </li>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData
                  .usersPrivilegeEmployeeSalarySetting.employeeSalarySettingAdd
              )
            "
          >
            <router-link
              to="/add-employee-salary-setting"
              :class="currentSubPage == 'employeeSalarySetting' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/employee-salary-setting.svg"
              /></span>
              <span class="title">{{ $t("EmployeeSalarySettingAdd") }}</span>
            </router-link>
          </li>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData
                  .usersPrivilegeEmployeeWorkSchedule.employeeWorkScheduleAdd
              )
            "
          >
            <router-link
              to="/add-employee-work-schedule"
              :class="currentSubPage == 'employeeWorkSchedule' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/work-schedule.svg"
              /></span>
              <span class="title">{{ $t("EmployeeWorkScheduleAdd") }}</span>
            </router-link>
          </li>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData
                  .usersPrivilegeEmployeeOfficialHolidaysPlans
                  .employeeOfficialHolidaysPlansAdd
              )
            "
          >
            <router-link
              to="/add-employee-official-holidays-plan"
              :class="
                currentSubPage == 'employeeOfficialHolidaysPlan' ? 'active' : ''
              "
            >
              <span class="icon"
                ><img src="@/assets/images/holiday.svg"
              /></span>
              <span class="title">{{
                $t("EmployeeOfficialHolidaysPlanAdd")
              }}</span>
            </router-link>
          </li>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData
                  .usersPrivilegeEmployeesInputSheetAttendance
                  .employeesInputSheetAttendanceView
              )
            "
          >
            <router-link
              to="/employees-input-sheets-attendance"
              :class="
                currentSubPage == 'employeesInputSheetsAttendance'
                  ? 'active'
                  : ''
              "
            >
              <span class="icon"><img src="@/assets/images/sheets.svg" /></span>
              <span class="title">{{
                $t("employeesInputSheetsAttendance")
              }}</span>
            </router-link>
          </li>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData
                  .usersPrivilegeEmployeesInputSheetAttendanceMerge
                  .EmployeesInputSheetAttendanceMergeView
              )
            "
          >
            <router-link
              to="/employees-input-sheet-attendance-merges"
              :class="
                currentSubPage == 'employeesInputSheetAttendanceMerges'
                  ? 'active'
                  : ''
              "
            >
              <span class="icon"><img src="@/assets/images/merge.svg" /></span>
              <span class="title">{{
                $t("employeesInputSheetAttendanceMerges")
              }}</span>
            </router-link>
          </li>
        </ul>
      </b-collapse>
    </li>
    <li
      class="sub-menu"
      v-if="
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeEmployeesAttendance
            .employeesAttendanceAttendanceList
        ) ||
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeEmployeesAttendance
            .employeesAttendanceDepatureList
        ) ||
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeEmployeesAttendance
            .employeesAttendanceViewAll
        )
      "
    >
      <a
        v-b-toggle.attendance
        :class="currentPage == 'attendance' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/attendance.svg" /></span>
        <span class="title">
          {{ $t("attendanceAndDeparture") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
      <b-collapse id="attendance">
        <ul>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData
                  .usersPrivilegeEmployeesAttendance
                  .employeesAttendanceAttendanceList
              )
            "
          >
            <router-link
              to="/add-attendance"
              :class="currentSubPage == 'add-attendance' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/add-attendance.svg"
              /></span>
              <span class="title">{{ $t("checkIn") }}</span>
            </router-link>
          </li>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData
                  .usersPrivilegeEmployeesAttendance
                  .employeesAttendanceDepatureList
              )
            "
          >
            <router-link
              to="/add-departure"
              :class="currentSubPage == 'add-departure' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/add-departure.svg"
              /></span>
              <span class="title">{{ $t("checkOut") }}</span>
            </router-link>
          </li>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData
                  .usersPrivilegeEmployeesAttendance.employeesAttendanceViewAll
              )
            "
          >
            <router-link
              to="/attendance-history"
              :class="currentSubPage == 'attendance-history' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/attendanceHistory.svg"
              /></span>
              <span class="title">{{
                $t("attendanceAndDepartureHistory")
              }}</span>
            </router-link>
          </li>
        </ul>
      </b-collapse>
    </li>
    <!-- <li
      v-if="
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeClients.clientsView
        )
      "
    >
      <router-link
        to="/clients"
        :class="currentPage == 'clients' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/clients.svg" /></span>
        <span class="title">{{ $t("clients") }}</span>
      </router-link>
    </li> -->

    <!-- #region Finance -->
    <li
      class="sub-menu"
      v-if="
        checkPrivilege(usersBranchPrivilageView.treasuriesView) ||
        checkPrivilege(usersBranchPrivilageView.expensesView) ||
        checkPrivilege(usersBranchPrivilageView.revenuesView) ||
        checkPrivilege(usersBranchPrivilageView.treasuryTransactionsView) ||
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeCustodies.custodiesView
        )
      "
    >
      <a v-b-toggle.finance :class="currentPage == 'finance' ? 'active' : ''">
        <span class="icon"><img src="@/assets/images/finance.svg" /></span>
        <span class="title">
          {{ $t("finance") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
      <b-collapse id="finance">
        <ul>
          <li v-if="checkPrivilege(usersBranchPrivilageView.treasuriesView)">
            <router-link
              to="/treasuries"
              :class="currentSubPage == 'treasuries' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/treasuries.svg"
              /></span>
              <span class="title">{{ $t("theTreasuries") }}</span>
            </router-link>
          </li>
          <li
            v-if="
              checkPrivilege(usersBranchPrivilageView.treasuryTransactionsView)
            "
          >
            <router-link
              to="/treasuries-transactions"
              :class="
                currentSubPage == 'treasuries-transactions' ? 'active' : ''
              "
            >
              <span class="icon"
                ><img src="@/assets/images/treasuries-transactions.svg"
              /></span>
              <span class="title">{{ $t("treasuriesTransactions") }}</span>
            </router-link>
          </li>

          <li v-if="checkPrivilege(usersBranchPrivilageView.revenuesView)">
            <router-link
              to="/revenues"
              :class="currentSubPage == 'revenues' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/revenues.svg"
              /></span>
              <span class="title">{{ $t("revenues") }}</span>
            </router-link>
          </li>
          <li v-if="checkPrivilege(usersBranchPrivilageView.expensesView)">
            <router-link
              to="/expenses"
              :class="currentSubPage == 'expenses' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/expenses.svg"
              /></span>
              <span class="title">{{ $t("expenses") }}</span>
            </router-link>
          </li>

          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData.usersPrivilegeCustodies
                  .custodyViewAll
              ) ||
              checkPrivilege(
                usersEstablishmentPrivilegeData.usersPrivilegeCustodies
                  .custodyViewSelf
              )
            "
          >
            <router-link
              :to="{ name: 'Custodies' }"
              :class="currentSubPage == 'custodies' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/Custodies.svg"
              /></span>
              <span class="title">{{ $t("Custodies.modelName") }}</span>
            </router-link>
          </li>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData
                  .usersPrivilegeCustodyExpenseOperations
                  .custodyExpenseOperationsViewAll
              ) ||
              checkPrivilege(
                usersEstablishmentPrivilegeData
                  .usersPrivilegeCustodyExpenseOperations
                  .custodyExpenseOperationsViewSelf
              )
            "
          >
            <router-link
              :to="{ name: 'CustodyExpenseOperations' }"
              :class="
                currentSubPage == 'CustodyExpenseOperations' ? 'active' : ''
              "
            >
              <span class="icon"
                ><img src="@/assets/images/CustodyExpenseOperations.svg"
              /></span>
              <span class="title">{{
                $t("CustodyExpenseOperations.modelName")
              }}</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'SalaryInquiries' }"
              :class="currentSubPage == 'SalaryInquiries' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/SalaryInquiries.svg"
              /></span>
              <span class="title">{{ $t("SalaryInquiries.modelName") }}</span>
            </router-link>
          </li>
        </ul>
      </b-collapse>
    </li>
    <!-- #endregion Finance -->

    <li
      v-if="
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeBranches.branchesView
        )
      "
    >
      <router-link
        to="/branches"
        :class="currentPage == 'branches' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/branches.svg" /></span>
        <span class="title">{{ $t("theBranches") }}</span>
      </router-link>
    </li>
    <li
      v-if="
        checkPrivilege(
          usersEstablishmentPrivilegeData
            .usersPrivilegeEmployeesSettlementMovement
            .employeesSettlementMovementViewAll
        )
      "
    >
      <router-link
        to="/points"
        :class="currentPage == 'points' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/points.svg" /></span>
        <span class="title">{{ $t("points") }}</span>
      </router-link>
    </li>
    <li>
      <router-link
        to="/reports"
        :class="currentPage == 'reports' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/reports.svg" /></span>
        <span class="title">{{ $t("reports") }}</span>
      </router-link>
    </li>

    <li
      class="sub-menu"
      v-if="
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeUsers
            .usersEstablishmentPrivilegeView
        ) ||
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeUsers
            .usersBranchPrivilegeView
        )
      "
    >
      <a
        v-b-toggle.privileges
        :class="currentPage == 'privileges' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/privileges.svg" /></span>
        <span class="title">
          {{ $t("privileges") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
      <b-collapse id="privileges">
        <ul>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData.usersPrivilegeUsers
                  .usersEstablishmentPrivilegeView
              )
            "
          >
            <router-link
              to="/establishment-privileges"
              :class="
                currentSubPage == 'establishment-privileges' ? 'active' : ''
              "
            >
              <span class="icon"
                ><img src="@/assets/images/establishment-privileges.svg"
              /></span>
              <span class="title">{{
                $t("privilegesMsgs.establishment-privileges")
              }}</span>
            </router-link>
          </li>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData.usersPrivilegeUsers
                  .usersBranchPrivilegeView
              )
            "
          >
            <router-link
              to="/branch-privileges"
              :class="currentSubPage == 'branch-privileges' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/branch-privileges.svg"
              /></span>
              <span class="title">{{
                $t("privilegesMsgs.branch-privileges")
              }}</span>
            </router-link>
          </li>
          <!-- <li>
            <router-link
              to="/store-privileges"
              :class="currentSubPage == 'store-privileges' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/store-privileges.svg"
              /></span>
              <span class="title">{{
                $t("privilegesMsgs.store-privileges")
              }}</span>
            </router-link>
          </li> -->
        </ul>
      </b-collapse>
    </li>

    <!-- <li>
      <router-link
        to="/stores"
        :class="currentPage == 'stores' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/stores.svg" /></span>
        <span class="title">{{ $t("theStores") }}</span>
      </router-link>
    </li> -->

    <li
      class="sub-menu"
      v-if="
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeEstablishmentsSettings
            .establishmentsSettingsView
        ) ||
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegeEstablishmentYearSetting
            .establishmentYearSettingView
        ) ||
        checkPrivilege(
          usersEstablishmentPrivilegeData.usersPrivilegAdditionalSettings
            .additionalSettingsView
        )
      "
    >
      <a v-b-toggle.settings :class="currentPage == 'settings' ? 'active' : ''">
        <span class="icon"><img src="@/assets/images/settings.svg" /></span>
        <span class="title">
          {{ $t("settings") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
      <b-collapse id="settings">
        <ul>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData
                  .usersPrivilegeEstablishmentsSettings
                  .establishmentsSettingsView
              ) ||
              checkPrivilege(
                usersEstablishmentPrivilegeData
                  .usersPrivilegeEstablishmentYearSetting
                  .establishmentYearSettingView
              )
            "
          >
            <router-link
              to="/settings-establishment"
              :class="
                currentSubPage == 'settings-establishment' ? 'active' : ''
              "
            >
              <span class="icon"
                ><img src="@/assets/images/settings-establishment.svg"
              /></span>
              <span class="title">{{ $t("settings-establishment") }}</span>
            </router-link>
          </li>
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData.usersPrivilegAdditionalSettings
                  .additionalSettingsView
              )
            "
          >
            <router-link
              to="/settings-salary"
              :class="currentSubPage == 'settings-salary' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/settings-salary.svg"
              /></span>
              <span class="title">{{ $t("settings-salary") }}</span>
            </router-link>
          </li>
          <!-- <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData.usersPrivilegAdditionalSettings
                  .additionalSettingsView
              )
            "
          >
            <router-link
              to="/settings-branch"
              :class="currentSubPage == 'SettingsBranch' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/branches.svg"
              /></span>
              <span class="title">{{ $t("settingsBranch") }}</span>
            </router-link>
          </li> -->
          <li
            v-if="
              checkPrivilege(
                usersEstablishmentPrivilegeData.usersPrivilegAdditionalSettings
                  .additionalSettingsView
              )
            "
          >
            <router-link
              to="/settings-other"
              :class="currentSubPage == 'settings-other' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/settings-other.svg"
              /></span>
              <span class="title">{{ $t("settings-other") }}</span>
            </router-link>
          </li>
        </ul>
      </b-collapse>
    </li>
  </ul>
</template>

<script>
import { checkPrivilege } from "./../../utils/functions";
import UsersEstablishmentPrivilege from "@/models/privileges/establishmentPrivileges/UsersEstablishmentPrivilege";
import UsersBranchPrivilageView from "@/models/privileges/branchPrivileges/UsersBranchPrivilageView";

export default {
  name: "Sidebar",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      usersEstablishmentPrivilegeData:
        this.$store.getters.usersEstablishmentPrivilegeData ||
        new UsersEstablishmentPrivilege(),
      usersBranchPrivilageView:
        this.$store.getters.usersBranchPrivilageView ||
        new UsersBranchPrivilageView(),
      currentPage: "",
      currentSubPage: "",
    };
  },
  methods: {
    getPageInfo() {
      this.currentPage = this.$route.meta.currentPage || "";
      this.currentSubPage = this.$route.meta.currentSubPage || "";
    },
    checkPrivilege,
  },
  watch: {
    $route() {
      this.getPageInfo();
    },
  },
  async created() {
    this.getPageInfo();
  },
};
</script>
