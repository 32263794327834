<template>
  <div class="time-label-group" :class="className">
    <vue-timepicker
      :input-class="['time-input-field']"
      :name="id"
      :id="id"
      manual-input
      autocomplete="on"
      :time-value.sync="yourData"
      v-model="theValue"
      @change="changeHandler"
      :format="format"
      :hourLabel="$t('timePiker.hours')"
      :minuteLabel="$t('timePiker.minutes')"
      :secondLabel="$t('timePiker.seconds')"
      :apmLabel="$t('timePiker.apmLabel')"
      :amText="$t('timePiker.amText')"
      :pmText="$t('timePiker.pmText')"
      :placeholder="$t('timePiker.select')"
      input-width="100%"
    >
      <template v-slot:icon>
        <img :src="require('@/assets/images/' + imgName)" />
      </template>
    </vue-timepicker>
    <label :for="id">{{ title ? title : $t("notFound") }}</label>
  </div>
</template>

<script>
import VueTimepicker from "vue2-timepicker";

export default {
  name: "TimePicker",
  props: {
    value: {
      default: "",
    },
    className: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "title",
    },
    imgName: {
      type: String,
      default: "time.svg",
    },
    mode12: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      theValue: this.value,
      yourData: {},
    };
  },
  components: {
    VueTimepicker,
  },
  methods: {
    dataHundler() {
      if (this.theValue) {
        let timeItems = this.theValue.split(":");
        let hour = "00";
        let minute = "00";
        let second = "00";
        let apm = "AM";

        // update time items depend on mode
        timeItems = this.mode12
          ? [timeItems[0], timeItems[1], ...timeItems[2].split(" ")]
          : timeItems;

        // set hour intinal theValue depend on mode
        if (this.mode12) {
          hour = `${timeItems[0] != "hh" ? timeItems[0] : "12"}`;
        } else {
          hour = `${timeItems[0] != "HH" ? timeItems[0] : `${hour}`}`;
        }
        minute = `${timeItems[1] != "mm" ? timeItems[1] : `${minute}`}`;
        second = `${timeItems[2] != "ss" ? timeItems[2] : `${minute}`}`;
        if (timeItems[3]) {
          apm = timeItems[3] != "A" ? ` ${timeItems[3]}` : ` AM`;
        }

        this.theValue = `${hour}:${minute}:${second}`;
        if (timeItems[3]) {
          this.theValue += apm;
        }

        if (this.mode12) {
          this.yourData = {
            hh: hour,
            mm: minute,
            ss: second,
            A: apm,
          };
        } else {
          this.yourData = {
            HH: hour,
            mm: minute,
            ss: second,
          };
        }
      }
    },
    changeHandler() {
      this.dataHundler();
      this.$emit("changeValue", this.theValue);
    },
  },
  computed: {
    format() {
      return this.mode12 ? "hh:mm:ss A" : "HH:mm:ss";
    },
  },

  // created() {
  //   this.dataHundler();
  // },
};
</script>
