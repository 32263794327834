<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg
      v-if="exceptionMsg"
      :msg="exceptionMsg"
      :image="exceptionImg"
    />

    <div
      v-if="
        employeeBranches.employeeBranchesData != undefined &&
        employeeBranches.employeeBranchesData.length != 0
      "
    >
      <EmployeeBranchesTable
        v-on:setEmployeeBranchData="
          employeeBranches.employeeBranch.fillData($event)
        "
        :employeeBranchesData="employeeBranches.employeeBranchesData"
        :pagination="employeeBranches.pagination"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="employeeBranches.pagination"
        v-on:changePage="changePage"
      />
      <EmployeeBranchInfo
        :employeeBranchData="employeeBranches.employeeBranch"
      />
      <EmployeeBranchDelete
        :employeeBranchData="employeeBranches.employeeBranch"
        v-on:refresh="getAllEmployeeBranches()"
      />
    </div>

    <EmployeeBranchFloatBtns
      :filterEmployeeBranchData="employeeBranches.filterData"
      v-on:search="getAllEmployeeBranches()"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import EmployeeBranchesTable from "@/components/employees/employeeBranches/EmployeeBranchesTable.vue";
import EmployeeBranchInfo from "@/components/employees/employeeBranches/EmployeeBranchInfo.vue";
import EmployeeBranchDelete from "@/components/employees/employeeBranches/EmployeeBranchDelete.vue";
import EmployeeBranchFloatBtns from "@/components/employees/employeeBranches/EmployeeBranchFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import EmployeeBranches from "@/models/employees/employeeBranches/EmployeeBranches";
import createToastMixin from "@/utils/create-toast-mixin";
import currentTabMixin from "@/utils/currentTab-mixin";
import { mapGetters } from "vuex";

export default {
  name: "EmployeeBranches",
  // props: ["employeeToken"],
  mixins: [createToastMixin, currentTabMixin],
  computed: {
    ...mapGetters(["employeeToken"]),
  },
  components: {
    PreLoader,
    ExceptionWithImg,
    EmployeeBranchesTable,
    EmployeeBranchInfo,
    EmployeeBranchDelete,
    EmployeeBranchFloatBtns,
    Pagination,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      employeeBranches: new EmployeeBranches(),
    };
  },
  methods: {
    changePage(page) {
      this.employeeBranches.pagination.selfPage = page;
      this.getAllEmployeeBranches();
    },
    refresh(data) {
      this.employeeBranches.filterData.fillData(data);
      this.employeeBranches.employeeBranchesData = [];
      this.getAllEmployeeBranches();
    },
    async getAllEmployeeBranches() {
      this.isLoading = true;
      try {
        const response =
          await this.employeeBranches.employeeBranch.getAllEmployeeBranches(
            this.language,
            this.userAuthorizeToken,
            this.employeeBranches.pagination,
            this.employeeBranches.filterData
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.employeeBranches.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.employeeBranches.employeeBranchesData = [];
          this.exceptionImg = "";
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.employeeBranches.employeeBranchesData = [];
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.employeeBranches.employeeBranchesData = [];
          this.exceptionImg = "illustrator-api-catch.svg";
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.employeeBranches.employeeBranchesData = [];
        this.exceptionImg = "illustrator-somethingWrong.svg";
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  async created() {
    if (this.currentTab && this.employeeToken) {
      this.employeeBranches.filterData.employeeToken = this.employeeToken;
    }
    this.employeeBranches.filterData.branchToken =
      this.$route.params.branchToken || "";
    this.getAllEmployeeBranches();
  },
};
</script>
