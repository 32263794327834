<template>
  <div v-if="!disabledStatus" class="form-label-group mt-2" :class="className">
    <b-form-checkbox
      v-model="theValue"
      name="check-button"
      switch
      :disabled="isDisabled"
    >
      {{ title ? title : $t("notFound") }}
    </b-form-checkbox>
  </div>
  <DataLabelGroup
    v-else
    :class="className"
    :value="value ? $t('yes') : $t('no')"
    :title="title"
    :imgName="imgName"
  />
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";

export default {
  name: "CustomCheckbox",
  data() {
    return {
      theValue: this.value,
    };
  },
  components: {
    DataLabelGroup,
  },
  methods: {},
  props: {
    className: {
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    value: {
      default: "",
    },
    title: {
      type: String,
      default: "title",
    },
    imgName: {
      type: String,
      default: "man.svg",
    },
    disabledStatus: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    theValue: function (val) {
      this.$emit("changeValue", val);
    },
    value: function (val) {
      this.theValue = val;
    },
  },
  async created() {},
};
</script>
