export default {
  data() {
    return {
      currentTab: "",
    };
  },
  methods: {
    async getPageInfo() {
      this.currentTab = this.$route.meta.currentTab || "";
    },
  },
  async created() {
    await this.getPageInfo();
  },
};
