var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.employeeBranchesData)?_c('div',{},[_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t("employeeName")))]),_c('th',[_vm._v(_vm._s(_vm.$t("branchName")))]),_c('th',[_vm._v(_vm._s(_vm.$t("setting.others.branchRolesMsgs.branchRole")))]),_c('th',[_vm._v(_vm._s(_vm.$t("employeeWorkPlaceType")))]),_c('th',[_vm._v(_vm._s(_vm.$t("info")))]),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData.usersPrivilegeEmployeeBranches
                  .employeeBranchesUpdate
              )
            )?_c('th',[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")]):_vm._e(),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData.usersPrivilegeEmployeeBranches
                  .employeeBranchesArchive
              )
            )?_c('th',[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")]):_vm._e()])]),_c('tbody',_vm._l((_vm.employeeBranchesData),function(employeeBranch,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(++index + _vm.pagination.currentIndex))]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(employeeBranch.employeeNameCurrent)))]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(employeeBranch.branchNameCurrent)))]),_c('td',[_vm._v(_vm._s(_vm.isDataExist(employeeBranch.branchRoleNameCurrent)))]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(employeeBranch.employeeWorkPlaceTypeNameCurrent))+" ")]),_c('td',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.EmployeeBranchInfo",modifiers:{"EmployeeBranchInfo":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('info')},on:{"click":function($event){return _vm.setEmployeeBranchData(employeeBranch)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/info.svg")}})])]),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData.usersPrivilegeEmployeeBranches
                  .employeeBranchesUpdate
              )
            )?_c('td',[_c('button',{attrs:{"title":_vm.$t('edit')},on:{"click":function($event){return _vm.routeTo(employeeBranch)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/pencil.svg")}})])]):_vm._e(),(
              _vm.checkPrivilege(
                _vm.usersEstablishmentPrivilegeData.usersPrivilegeEmployeeBranches
                  .employeeBranchesArchive
              )
            )?_c('td',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.EmployeeBranchDelete",modifiers:{"EmployeeBranchDelete":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setEmployeeBranchData(employeeBranch)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/trash.svg")}})])]):_vm._e()])}),0)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }