import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import PaginationData from "./../../../general/PaginationData";
import GrantsConstraintsPlansFilter from "./GrantsConstraintsPlansFilter";
import GrantsConstraintsPlansSlidDetails from "./../grantsConstraintsPlansSlidesDetails/GrantsConstraintsPlansSlidDetails";

export default class GrantsConstraintsPlan {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.grantsConstraintsPlansToken = "";
    this.grantsConstraintsPlansCode = "";
    this.grantsConstraintsPlansNameAr = "";
    this.grantsConstraintsPlansNameEn = "";
    this.grantsConstraintsPlansNameUnd = "";
    this.grantsConstraintsPlansNameCurrent = "";
    this.grantsConstraintsPlansNote = "";
    this.dateTimeActionAdd = "";
    this.dateActionAdd = "";
    this.TimeActionAdd = "";
    this.grantsConstraintsPlansArchiveStatus = false;
    this.grantsConstraintsPlansSlidesDetailsData = [
      new GrantsConstraintsPlansSlidDetails(),
    ];
  }
  fillData(data) {
    this.grantsConstraintsPlansToken = data.grantsConstraintsPlansToken;
    this.grantsConstraintsPlansCode = data.grantsConstraintsPlansCode;
    this.grantsConstraintsPlansNameAr = data.grantsConstraintsPlansNameAr;
    this.grantsConstraintsPlansNameEn = data.grantsConstraintsPlansNameEn;
    this.grantsConstraintsPlansNameUnd = data.grantsConstraintsPlansNameUnd;
    this.grantsConstraintsPlansNameCurrent =
      data.grantsConstraintsPlansNameCurrent;
    this.grantsConstraintsPlansNote = data.grantsConstraintsPlansNote;
    this.dateTimeActionAdd = data.dateTimeActionAdd;
    this.dateActionAdd = data.dateActionAdd;
    this.TimeActionAdd = data.TimeActionAdd;
    this.grantsConstraintsPlansArchiveStatus =
      data.grantsConstraintsPlansArchiveStatus;
    this.grantsConstraintsPlansSlidesDetailsData =
      data.grantsConstraintsPlansSlidesDetailsData;
  }

  async getGrantsConstraintsPlanses(
    language,
    userAuthorizeToken,
    pagination = new PaginationData(),
    filterData = new GrantsConstraintsPlansFilter()
  ) {
    try {
      let response = await axios.get(
        `/GrantsConstraintsPlans/GetGrantsConstraintsPlanses?language=${language}&userAuthorizeToken=${userAuthorizeToken}&cityArchiveStatus=false&page=${pagination.selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&textSearch=${filterData.textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getDialogOfGrantsConstraintsPlans(
    language,
    userAuthorizeToken,
    filterData = new GrantsConstraintsPlansFilter()
  ) {
    return await axios.get(
      `/GrantsConstraintsPlans/GetDialogOfGrantsConstraintsPlans?language=${language}&userAuthorizeToken=${userAuthorizeToken}&filterStatus=true&textSearch=${filterData.textSearch}`
    );
  }

  async getGrantsConstraintsPlansDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/GrantsConstraintsPlans/GetGrantsConstraintsPlansDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&grantsConstraintsPlansToken=${this.grantsConstraintsPlansToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateGrantsConstraintsPlans(language, userAuthorizeToken) {
    let data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      grantsConstraintsPlansToken: this.grantsConstraintsPlansToken,
      grantsConstraintsPlansCode: this.grantsConstraintsPlansCode,
      grantsConstraintsPlansNameAr: this.grantsConstraintsPlansNameAr,
      grantsConstraintsPlansNameEn: this.grantsConstraintsPlansNameEn,
      grantsConstraintsPlansNameUnd: this.grantsConstraintsPlansNameUnd,
      grantsConstraintsPlansNote: this.grantsConstraintsPlansNote,
      grantsConstraintsPlansSlidesDetailsData:
        this.grantsConstraintsPlansSlidesDetailsData,
    };

    try {
      if (
        this.grantsConstraintsPlansToken == "" ||
        this.grantsConstraintsPlansToken == undefined
      ) {
        return await axios.post(
          `/GrantsConstraintsPlans//AddGrantsConstraintsPlans`,
          data
        );
      } else {
        return await axios.post(
          `/GrantsConstraintsPlans//UpdateGrantsConstraintsPlans`,
          data
        );
      }
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveGrantsConstraintsPlans(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      grantsConstraintsPlansToken: this.grantsConstraintsPlansToken,
    };

    try {
      return await axios.post(
        `/GrantsConstraintsPlans//ArchiveGrantsConstraintsPlans`,
        data
      );
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
