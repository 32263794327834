export default class GrantsConstraintsPlansSlidesDetailsFilter {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.grantsConstraintsPlansToken = "";
  }
  fillData(data) {
    this.grantsConstraintsPlansToken = data.grantsConstraintsPlansToken;
  }
}
