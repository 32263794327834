import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";
import PaginationData from "./../../../general/PaginationData";
import GrantsConstraintsPlansSlidesDetailsFilter from "./GrantsConstraintsPlansSlidesDetailsFilter";

export default class GrantsConstraintsPlansSlidDetails {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.grantsConstraintsPlansSlidesDetailsToken = "";
    this.grantTypeToken = "";
    this.grantTypeNameCurrent = "";
    this.grantTypeNameEn = "";
    this.grantTypeNameAr = "";
    this.grantTypeNameUnd = "";
    this.grantsConstraintsPlansToken = "";
    this.grantsConstraintsPlansNameCurrent = "";
    this.grantsConstraintsMaximumNumberInYear = "";
    this.grantsConstraintsMaximumNumberInYearActivationStatus = false;
    this.grantsConstraintsMaximumNumberInMonth = "";
    this.grantsConstraintsMaximumNumberInMonthActivationStatus = false;
    this.grantsConstraintsMaximumNumberInDay = "";
    this.grantsConstraintsMaximumNumberInDayActivationStatus = false;
    this.grantsConstraintsMaximumCostInYear = "";
    this.grantsConstraintsMaximumCostInYearActivationStatus = false;
    this.grantsConstraintsMaximumCostInMonth = "";
    this.grantsConstraintsMaximumCostInMonthActivationStatus = false;
    this.grantsConstraintsMaximumCostInDay = "";
    this.grantsConstraintsMaximumCostInDayActivationStatus = false;
    this.grantsConstraintsMaximumHourFactorInYear = "";
    this.grantsConstraintsMaximumHourFactorInYearActivationStatus = false;
    this.grantsConstraintsMaximumHourFactorInMonth = "";
    this.grantsConstraintsMaximumHourFactorInMonthActivationStatus = false;
    this.grantsConstraintsMaximumHourFactorInDay = "";
    this.grantsConstraintsMaximumHourFactorInDayActivationStatus = false;
    this.sequentialCalculationOfHourFactorAndValue = false;
    this.grantsConstraintsPlansSlidesDetailsNote = "";
    this.grantsConstraintsPlansSlidesDetailsArchiveStatus = false;
  }
  fillData(data) {
    this.grantsConstraintsPlansSlidesDetailsToken =
      data.grantsConstraintsPlansSlidesDetailsToken;
    this.grantTypeToken = data.grantTypeToken;
    this.grantTypeNameCurrent = data.grantTypeNameCurrent;
    this.grantTypeNameEn = data.grantTypeNameEn;
    this.grantTypeNameAr = data.grantTypeNameAr;
    this.grantTypeNameUnd = data.grantTypeNameUnd;
    this.grantsConstraintsPlansToken = data.grantsConstraintsPlansToken;
    this.grantsConstraintsPlansNameCurrent =
      data.grantsConstraintsPlansNameCurrent;
    this.grantsConstraintsMaximumNumberInYear =
      data.grantsConstraintsMaximumNumberInYear;
    this.grantsConstraintsMaximumNumberInYearActivationStatus =
      data.grantsConstraintsMaximumNumberInYearActivationStatus;
    this.grantsConstraintsMaximumNumberInMonth =
      data.grantsConstraintsMaximumNumberInMonth;
    this.grantsConstraintsMaximumNumberInMonthActivationStatus =
      data.grantsConstraintsMaximumNumberInMonthActivationStatus;
    this.grantsConstraintsMaximumNumberInDay =
      data.grantsConstraintsMaximumNumberInDay;
    this.grantsConstraintsMaximumNumberInDayActivationStatus =
      data.grantsConstraintsMaximumNumberInDayActivationStatus;
    this.grantsConstraintsMaximumCostInYear =
      data.grantsConstraintsMaximumCostInYear;
    this.grantsConstraintsMaximumCostInYearActivationStatus =
      data.grantsConstraintsMaximumCostInYearActivationStatus;
    this.grantsConstraintsMaximumCostInMonth =
      data.grantsConstraintsMaximumCostInMonth;
    this.grantsConstraintsMaximumCostInMonthActivationStatus =
      data.grantsConstraintsMaximumCostInMonthActivationStatus;
    this.grantsConstraintsMaximumCostInDay =
      data.grantsConstraintsMaximumCostInDay;
    this.grantsConstraintsMaximumCostInDayActivationStatus =
      data.grantsConstraintsMaximumCostInDayActivationStatus;
    this.grantsConstraintsMaximumHourFactorInYear =
      data.grantsConstraintsMaximumHourFactorInYear;
    this.grantsConstraintsMaximumHourFactorInYearActivationStatus =
      data.grantsConstraintsMaximumHourFactorInYearActivationStatus;
    this.grantsConstraintsMaximumHourFactorInMonth =
      data.grantsConstraintsMaximumHourFactorInMonth;
    this.grantsConstraintsMaximumHourFactorInMonthActivationStatus =
      data.grantsConstraintsMaximumHourFactorInMonthActivationStatus;
    this.grantsConstraintsMaximumHourFactorInDay =
      data.grantsConstraintsMaximumHourFactorInDay;
    this.grantsConstraintsMaximumHourFactorInDayActivationStatus =
      data.grantsConstraintsMaximumHourFactorInDayActivationStatus;
    this.sequentialCalculationOfHourFactorAndValue =
      data.sequentialCalculationOfHourFactorAndValue;
    this.grantsConstraintsPlansSlidesDetailsNote =
      data.grantsConstraintsPlansSlidesDetailsNote;
    this.grantsConstraintsPlansSlidesDetailsArchiveStatus =
      data.grantsConstraintsPlansSlidesDetailsArchiveStatus;
  }

  async getAllGrantsConstraintsPlansSlidesDetails(
    language,
    userAuthorizeToken,
    pagination = new PaginationData(),
    filterData = new GrantsConstraintsPlansSlidesDetailsFilter()
  ) {
    try {
      let response = await axios.get(
        `/GrantsConstraintsPlansSlidesDetails/GetAllGrantsConstraintsPlansSlidesDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&cityArchiveStatus=false&page=${pagination.selfPage}&paginationStatus=true&pageSize=${PAGE_SIZE}&filterStatus=true&grantsConstraintsPlansToken=${filterData.grantsConstraintsPlansToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getGrantsConstraintsPlansSlidesDetailsDetails(
    language,
    userAuthorizeToken
  ) {
    try {
      let response = await axios.get(
        `/GrantsConstraintsPlansSlidesDetails/GetGrantsConstraintsPlansSlidesDetailsDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&grantsConstraintsPlansSlidesDetailsToken=${this.grantsConstraintsPlansSlidesDetailsToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async addOrUpdateGrantsConstraintsPlansSlidesDetails(
    language,
    userAuthorizeToken
  ) {
    let data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      grantsConstraintsPlansSlidesDetailsToken:
        this.grantsConstraintsPlansSlidesDetailsToken,
      grantsConstraintsPlansToken: this.grantsConstraintsPlansToken,
      grantTypeToken: this.grantTypeToken,
      grantsConstraintsMaximumNumberInDay:
        this.grantsConstraintsMaximumNumberInDay,
      grantsConstraintsMaximumNumberInDayActivationStatus:
        this.grantsConstraintsMaximumNumberInDayActivationStatus,
      grantsConstraintsMaximumNumberInMonth:
        this.grantsConstraintsMaximumNumberInMonth,
      grantsConstraintsMaximumNumberInMonthActivationStatus:
        this.grantsConstraintsMaximumNumberInMonthActivationStatus,
      grantsConstraintsMaximumNumberInYear:
        this.grantsConstraintsMaximumNumberInYear,
      grantsConstraintsMaximumNumberInYearActivationStatus:
        this.grantsConstraintsMaximumNumberInYearActivationStatus,
      grantsConstraintsMaximumCostInDay: this.grantsConstraintsMaximumCostInDay,
      grantsConstraintsMaximumCostInDayActivationStatus:
        this.grantsConstraintsMaximumCostInDayActivationStatus,
      grantsConstraintsMaximumCostInMonth:
        this.grantsConstraintsMaximumCostInMonth,
      grantsConstraintsMaximumCostInMonthActivationStatus:
        this.grantsConstraintsMaximumCostInMonthActivationStatus,
      grantsConstraintsMaximumCostInYear:
        this.grantsConstraintsMaximumCostInYear,
      grantsConstraintsMaximumCostInYearActivationStatus:
        this.grantsConstraintsMaximumCostInYearActivationStatus,
      grantsConstraintsMaximumHourFactorInDay:
        this.grantsConstraintsMaximumHourFactorInDay,
      grantsConstraintsMaximumHourFactorInDayActivationStatus:
        this.grantsConstraintsMaximumHourFactorInDayActivationStatus,
      grantsConstraintsMaximumHourFactorInMonth:
        this.grantsConstraintsMaximumHourFactorInMonth,
      grantsConstraintsMaximumHourFactorInMonthActivationStatus:
        this.grantsConstraintsMaximumHourFactorInMonthActivationStatus,
      grantsConstraintsMaximumHourFactorInYear:
        this.grantsConstraintsMaximumHourFactorInYear,
      grantsConstraintsMaximumHourFactorInYearActivationStatus:
        this.grantsConstraintsMaximumHourFactorInYearActivationStatus,
      sequentialCalculationOfHourFactorAndValue:
        this.sequentialCalculationOfHourFactorAndValue,
      grantsConstraintsPlansSlidesDetailsNote:
        this.grantsConstraintsPlansSlidesDetailsNote,
    };

    try {
      if (
        this.grantsConstraintsPlansSlidesDetailsToken == "" ||
        this.grantsConstraintsPlansSlidesDetailsToken == undefined
      ) {
        return await axios.post(
          `/GrantsConstraintsPlansSlidesDetails//AddGrantsConstraintsPlansSlidesDetails`,
          data
        );
      } else {
        return await axios.post(
          `/GrantsConstraintsPlansSlidesDetails//UpdatGrantsConstraintsPlansSlidesDetails`,
          data
        );
      }
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async archiveGrantsConstraintsPlansSlidesDetails(
    language,
    userAuthorizeToken
  ) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      grantsConstraintsPlansSlidesDetailsToken:
        this.grantsConstraintsPlansSlidesDetailsToken,
    };

    try {
      return await axios.post(
        `/GrantsConstraintsPlansSlidesDetails//ArchiveGrantsConstraintsPlansSlidesDetails`,
        data
      );
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
