export default class EmployeesFilter {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.employeeToken = "";
    this.employeeTypeToken = "";
    this.textSearch = "";
  }
  fillData(data) {
    this.employeeToken = data.employeeToken;
    this.employeeTypeToken = data.employeeTypeToken;
    this.textSearch = data.textSearch;
  }
}
